<div class="dialog-header">
  <button class="close-button" (click)="handleClose()">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <app-nps-survey
    [feature]="npsFeatureEnum.STATEMENT"
    (onSubmitSuccess)="handleSubmitSuccess()"
  ></app-nps-survey>
</div>
