import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { first, tap } from 'rxjs/operators';

import { NpsSurveyService } from '@app/shared/components/nps-survey/nps-survey.service';
import { RatingFeatureIdEnum } from '@app/shared/components/nps-survey/nps-survey.types';
import { StatementNpsSurveyDialogComponent } from '../statement-nps-survey-dialog/statement-nps-survey-dialog.component';
import { IStatementNpsSurveyDialogCloseResult } from '../statement-nps-survey-dialog/statement-nps-survey-dialog.types';

@Component({
  selector: 'app-open-nps-survey-button',
  templateUrl: './open-nps-survey-button.component.html',
  styleUrl: './open-nps-survey-button.component.scss'
})
export class OpenNpsSurveyButtonComponent
  implements OnInit
{
  isNpsDialogOpened = false;
  isEligible = false;

  constructor(
    private dialog: MatDialog,
    private npsSurveyService: NpsSurveyService
  ) {}

  ngOnInit(): void {
    this.checkIfIsNpsEligibleOnStatement();
  }

  checkIfIsNpsEligibleOnStatement() {
    const ratingFeatId = RatingFeatureIdEnum.STATEMENT;

    this.npsSurveyService
      .getDaysSinceLastSurveyAnswered(ratingFeatId)
      .subscribe((daysSinceLastNpsAnswered) => {
        this.isEligible =
          daysSinceLastNpsAnswered === null ||
          daysSinceLastNpsAnswered >= 90;
      });
  }

  open() {
    this.isNpsDialogOpened = true;
    this.dialog
      .open(StatementNpsSurveyDialogComponent)
      .afterClosed()
      .pipe(
        tap(
          (
            result?: IStatementNpsSurveyDialogCloseResult
          ) => {
            this.isNpsDialogOpened = false;
            if (!!result?.isNpsSubmitted) {
              this.isEligible = false;
            }
          }
        )
      )
      .subscribe();
  }
}
