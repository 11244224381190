import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { NpsFeatureEnum } from '@app/shared/components/nps-survey/nps-survey.types';
import { IStatementNpsSurveyDialogCloseResult } from './statement-nps-survey-dialog.types';

@Component({
  selector: 'app-statement-nps-survey-dialog',
  templateUrl:
    './statement-nps-survey-dialog.component.html',
  styleUrl: './statement-nps-survey-dialog.component.scss'
})
export class StatementNpsSurveyDialogComponent {
  npsFeatureEnum = NpsFeatureEnum;

  isNpsSubmitted = false;

  constructor(
    public dialogRef: MatDialogRef<StatementNpsSurveyDialogComponent>
  ) {}

  handleClose(): void {
    const dialogResult: IStatementNpsSurveyDialogCloseResult =
      {
        isNpsSubmitted: this.isNpsSubmitted
      };
    this.dialogRef.close(dialogResult);
  }

  handleSubmitSuccess() {
    this.isNpsSubmitted = true;

    setTimeout(() => {
      this.handleClose();
    }, 3000);
  }
}
